import { AxiosHelper } from './ms-utils';

const BROADCAST = {
  LIST_ALL: '',
  BY_ID: id => `/id/${id}`,
  LIST_BY_CHILD_ID: childId => `/child/${childId}`,
  RECALL: id => `/recall/${id}`,
  SAVE: `/save`,
  MARK_READ: id => `/read/${id}`,
  DELETE: id => `/id/${id}`,
  UPLOAD_ATTACHMENT: schoolId =>
    `/attachment/get-signed-upload-url/${schoolId}`,
  GET_SIGNED_PRIVATE_URL: '/attachment/get-file-signed-access-url',
};

const NOTIFICATION = {
  LIST: '',
  MARK_READ: '/mark-as-read',
};

export const HEALTH_ATTENDANCE_ENDPOINT = {
  GET_CHECK_IN_OUT_TOKEN: '/attendance/check-in-out-token',
  GET_CENTRE_ATTENDANCE_SUMMARIES: '/attendance/centre-attendance-summaries',
  GET_CENTRE_ATTENDANCE_SUMMARIES_FOR_STAFF:
    '/attendance/centre-attendance-summaries-for-staff',
};

export const BROADCAST_ENDPOINT = BROADCAST;
export const NOTIFICATION_ENDPOINT = NOTIFICATION;
export const CENTRE_SVC_ENDPOINT = {
  GET_CENTRES_BY_SCHOOL_IDS: '/get-centres-by-school-ids',
};
export const REPORT_SVC_ENDPOINT = {
  GET_REPORTS: '/',
  GET_DOCUMENTS: '/documents',
  GENERATE_REPORT: '/generate',
};

// Axios instance for notification service, will update when MS supports API Gateway
export const notificationMsInstance = AxiosHelper({
  baseURL: process.env.REACT_APP_MS_NOTIFICATION_URL,
});

export const healthAttendanceMsInstance = () =>
  AxiosHelper({
    baseURL: process.env.REACT_APP_MS_HEALTH_ATTENDANCE_URL,
  });

export const reportSerivce = () =>
  AxiosHelper({
    baseURL: process.env.REACT_APP_MS_REPORT_SVC_URL,
  });

export const centreSerivce = () =>
  AxiosHelper({
    baseURL: process.env.REACT_APP_MS_CENTRE_SVC_URL,
  });
